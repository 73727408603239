<template>
  <component :is="isAuthenticated ? 'LayoutManager' : 'LandingPageLayout'">
    <div
      :class="[
        'terms__conditions--container',
        { 'loggedin-terms': isAuthenticated },
        { container: !isAuthenticated }
      ]"
      id="terms-page"
    >
      <LxpBreadcrumb
        :items="breadcrumb1"
        @click="navigateToLink"
        id="top-breadcrumb"
        v-if="isAuthenticated"
      />
      <div class="mobile__nav--wrapper d-md-none" id="mobile-nav">
        <nav class="navbar heading__item--container">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{{ currentItem }}</span>
                <img src="@/assets/images/landing/course/arrow.svg" alt="" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li v-for="item in navItems" :key="item.id">
                  <a
                    class="dropdown-item"
                    :class="{ active: currentItem === item.name }"
                    :href="`#${item.id}`"
                    @click.prevent="scrollToContent(item.id, item.name)"
                  >
                    {{ item.name }}
                  </a>
                  <ul v-if="item.subItems" class="subdropdown-menu">
                    <li v-for="subItem in item.subItems" :key="subItem.id">
                      <a
                        class="dropdown-item"
                        :class="{ active: currentItem === subItem.name }"
                        :href="`#${subItem.id}`"
                        @click.prevent="
                          scrollToContent(subItem.id, subItem.name)
                        "
                      >
                        {{ subItem.name }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <nav id="terms-list" class="heading__item--container d-none d-md-block">
        <nav class="nav">
          <div v-for="(item, index) in navItems" :key="item.id">
            <a
              class="nav-link no-active"
              :href="`#${item.id}`"
              @click.prevent="toggleMenu(item.id)"
            >
              {{ item.name }}
              <span>
                <img
                  src="@/assets/images/vle/chevron-blue.svg"
                  alt=""
                  :style="
                    isSubMenuOpen(item.id) ? 'transform: rotate(180deg);' : ''
                  "
                />
              </span>
            </a>
            <nav
              v-if="item.subItems"
              :class="['nav', { 'd-none': !isSubMenuOpen(item.id) }]"
            >
              <a
                v-for="(subItem, subIndex) in item.subItems"
                :class="['nav-link', { active: index === 0 && subIndex === 0 }]"
                :href="`#${subItem.id}`"
                :key="subItem.id"
                @click.prevent="scrollToContent(subItem.id, subItem.name)"
              >
                {{ subItem.name }}
              </a>
            </nav>
          </div>
        </nav>
      </nav>
      <div class="content__item--container">
        <div id="item1" class="content-item">
          <LxpBreadcrumb
            :items="breadcrumb1"
            @click="navigateToLink"
            v-if="!isAuthenticated"
          />
          <h1 class="main-heading">
            {{ $t("terms_conditions.category1") }}
          </h1>
          <div class="disclaimer-information">
            <img :src="disclaimerIcon" alt="disclaimer" />
            {{ $t("terms_conditions.disclaimers.content") }}
          </div>
          <h2 class="item-heading">
            {{ $t("terms_conditions.disclaimers.title") }}
          </h2>
          <div class="item-body">
            WHOA Terms of Use and Disclaimers<br /><br />
            The WHOA application for the WHO Academy is a product of WHO.<br /><br />
            The app is available for download without charge on the Google Play
            store and App Store website. By using the app, the user agrees to
            these terms of use and disclaimers. Please read through them
            carefully.<br /><br />
            The app provides guidance, learning materials and tool box for
            health care workers, makes it accessible for the health
            professionals.<br /><br />
            WHO makes no warranties or representations regarding the contents,
            appearance, completeness, technical specifications, or accuracy of
            the app. WHO disclaims all responsibility relating to, and shall not
            be liable for any use of, the app, the results of such use, or the
            reliance thereon. The responsibility for the interpretation and use
            of any content, including third-party content, lies solely and
            exclusively with the user.<br /><br />
            Content in the app has been translated by third parties and WHO is
            not responsible for the accuracy of such translated content. In the
            event of any inconsistency between the English and any other
            language version, the original English version shall be the binding
            and authentic version.<br /><br />
            The hyper-links to GOARN partners in this app and the content
            included therein is being provided by WHO for general informational
            purposes only. The provision of the hyper-links does not imply the
            approval or endorsement of those sites by WHO.<br /><br />
            WHO reserves the right to make updates and changes to the app
            without notice, and accepts no liability for any errors or omissions
            in this regard.<br /><br />
            The user of the app is responsible for the interpretation and use of
            the analysis and outputs performed by the app. The submission of
            content to the app does not imply WHO’s approval or endorsement of
            that content, or that the content is appropriate for any purpose or
            meets any established standard or requirement.<br /><br />
            Any designations employed in, or presentation by the user in its use
            of, the app, including tables and maps, do not imply the expression
            of any opinion whatsoever on the part of the World Health
            Organization concerning the legal status of any country, territory,
            city or area or of its authorities, or concerning the delimitation
            of its frontiers and boundaries.<br /><br />
            Any reference to third parties or the mention of specific companies
            or of certain manufacturers' in the app does not imply that they are
            endorsed or recommended by the World Health Organization in
            preference to others of a similar nature that are not mentioned.<br /><br />
            Nothing in or relating herein or to the use of the app shall be
            deemed a waiver of any of the privileges and immunities enjoyed by
            WHO under national or international law and/or as submitting WHO to
            any national court jurisdiction.
          </div>
        </div>
        <div id="item2" class="content-item">
          <h1 class="main-heading">
            {{ $t("terms_conditions.category2") }}
          </h1>
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.1.heading") }}
          </h2>
          <div class="item-body">
            This WHO Data Protection Policy (the <b>“Policy”</b>) sets out the
            rules and principles relating to the Processing by and within WHO of
            Personal Data of individuals.<br /><br />
            In pursuit of its mandate, WHO is often required to process Personal
            Data. This may include the need to share (transferring or receiving)
            Personal Data with Third Parties in the day to day operations of
            WHO. Third Parties may include suppliers of goods and services,
            contractors, consultants, implementing partners, collaborators,
            Member States, donors and other stakeholders.<br /><br />
            The collection, analysis, publication and dissemination of
            health-related data is a core part of WHO’s mandate. The
            Constitutional functions of WHO are, among others, to “establish and
            maintain such administrative and technical services as may be
            required, including epidemiological and statistical services; to
            promote (…) research in the field of health; and to provide
            information (…) in the field of health” (Article 2 of the WHO
            Constitution).<br /><br />
            WHO is cognizant of the fact that it holds and processes sensitive
            medical data and data of vulnerable or marginalized individuals and
            groups of individuals, including children, which require careful
            handling and particular attention.<br /><br />
            This Policy also considers the regulatory evolution in the field of
            data protection, as well as the Personal Data Protection and Privacy
            Principles of the United Nations (<b>“UN”</b>) Privacy Policy Group
            (<b>“PPG”</b>) adopted in September 2018 and endorsed by the
            High-Level Committee of Management (<b>“HLCM”</b>) in October 2018,
            and the WHO Data Principles<sup>1</sup>.<br /><br />
            This Policy should be read in conjunction with other policies and
            procedures of WHO, notably the Policy on Use and Sharing of Data
            Collected in Member States by WHO Outside the Context of Public
            Health Emergencies2, the Policy statement on Data Sharing by WHO in
            the Context of Public Health Emergencies3, the Information
            Disclosure Policy4 and , WHO’s information technology and
            information security policies, section XXII of the eManual, as well
            as the WHO Staff Regulations and Staff Rules and the WHO Code of
            Ethics.<br /><br />
            1
            <a target="_blank" href="https://www.who.int/data/principles">
              https://www.who.int/data/principles</a
            ><br />
            2
            <a
              target="_blank"
              href="https://www.who.int/publishing/datapolicy/en/"
            >
              https://www.who.int/publishing/datapolicy/en/</a
            ><br />
            3
            <a
              target="_blank"
              href="http://www.who.int/publishing/datapolicy/en/"
            >
              http://www.who.int/publishing/datapolicy/en/</a
            ><br />
            4
            <a
              target="_blank"
              href="https://intranet.who.int/homes/cre/ethics/disclosure/"
            >
              https://intranet.who.int/homes/cre/ethics/disclosure/</a
            ><br /><br />
            This Policy may be complemented by operational guidelines (standard
            operating procedures) or other policies that will provide guidance
            on its implementation, supervision and accountability.<br /><br />
            This Policy may be revised over time and will be evaluated by the
            WHO Secretariat after an initial twoyear period following its entry
            into force.<br /><br />
            Capitalized terms used in this Policy have the meanings ascribed to
            them in the “Definitions” section below or in the Policy.
          </div>
        </div>
        <div id="item2-1" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.2.heading") }}
          </h2>
          <div class="item-body">
            For the purposes of this Policy, the following definitions apply:<br /><br />
            <table class="content-table">
              <tr>
                <td>Anonymized Data</td>
                <td>
                  Information which does not relate to an identified or
                  identifiable natural person or to Personal Data rendered
                  anonymous in such a manner that the Data Subject is not or no
                  longer identifiable.
                </td>
              </tr>
              <tr>
                <td>Automated Processing</td>
                <td>
                  Automated individual decision-making (i.e. making a decision
                  solely by automated means without any human involvement),
                  including profiling (i.e. automated processing of Personal
                  Data to evaluate certain elements about a Data Subject).
                </td>
              </tr>
              <tr>
                <td>Consent</td>
                <td>
                  Clear Consent means any freely given and informed indication
                  of an agreement by the Data Subject to the Processing of
                  his/her Personal Data. It may be given either by a written or
                  oral statement or other clear affirmative action.<br /><br />
                  Explicit Consent means a very clear and specific statement of
                  consent, which must be in writing. This could include
                  electronic formats (such as sending an email, uploading a
                  scanned document carrying the signature of the Data Subject,
                  or using an electronic signature).
                </td>
              </tr>
              <tr>
                <td>Data Controller</td>
                <td>
                  The person or entity which, alone or jointly with others,
                  determines the purposes and means of the Processing of
                  Personal Data. A Data Controller has primary responsibility
                  for the protection of Personal Data
                </td>
              </tr>
              <tr>
                <td>Data Processor</td>
                <td>
                  A person or entity which Processes Personal Data on behalf of,
                  or under instructions from, the Data Controller
                </td>
              </tr>
              <tr>
                <td>Data Protection and Privacy Officer or DPPO</td>
                <td>
                  The officer who oversees the implementation of this Policy and
                  performs the other functions listed in paragraph 6.3 of this
                  Policy. The function of DPPO shall be performed by a WHO staff
                  member.
                </td>
              </tr>
              <tr>
                <td>Data Subject</td>
                <td>
                  An individual whose Personal Data is subject to Processing.
                </td>
              </tr>
              <tr>
                <td>Health Data</td>
                <td>
                  Personal Data related to the physical or mental health of a
                  natural person, including the provision of health care
                  services, which reveal information about his or her current or
                  past health status. This includes Personal Data derived from
                  the testing or examination of a human body part or bodily
                  substance, including from genetic data and biological samples.
                </td>
              </tr>
              <tr>
                <td>Personal Data</td>
                <td>
                  Any information relating to an individual who is or can be
                  identified from that information. Personal Data includes
                  biographical data (biodata) such as name, sex, civil status,
                  date and place of birth, country of origin, country of
                  residence, individual registration number, occupation,
                  religion and ethnicity, biometric data such as a photograph,
                  fingerprint, facial or iris image, as well as any expression
                  of opinion about the individual, such as assessments of the
                  status and/or specific needs. Personal Data also includes data
                  that, when combined with other data, can indirectly identify
                  an individual.
                </td>
              </tr>
              <tr>
                <td>Personal Data Breach</td>
                <td>
                  A breach of data security leading to the accidental or
                  unlawful/illegitimate destruction, loss, alteration,
                  unauthorized disclosure of, or access to, Personal Data
                  transferred, stored or otherwise processed.
                </td>
              </tr>
              <tr>
                <td>Policy</td>
                <td>
                  The present World Health Organization Personal Data Protection
                  Policy
                </td>
              </tr>
              <tr>
                <td>Processing (of Personal Data)</td>
                <td>
                  Any operation, or set of operations, automated or not, which
                  is performed on Personal Data, including but not limited to
                  the collection, recording, organization, structuring, storage,
                  adaption or alteration, retrieval, consultation, use, transfer
                  (whether in computerized, oral or written form), dissemination
                  or otherwise making available, correction, or destruction
                </td>
              </tr>
              <tr>
                <td>Sensitive Personal Data</td>
                <td>
                  Personal Data revealing racial or ethnic origin, political
                  opinions, religious or philosophical beliefs, or trade union
                  membership, as well as genetic or biometric data uniquely
                  identifying a natural person, Health Data or Personal Data
                  relating to a natural person’s sex life or sexual orientation.
                </td>
              </tr>
              <tr>
                <td>Third Party</td>
                <td>
                  Any natural or legal person other than the Data Subject or
                  WHO. Examples of Third Parties include governments of WHO
                  Member States, international governmental organizations
                  (IGOs), non-governmental organizations (NGOs), UN system
                  agencies, private sector entities and other non-State actors,
                  and individuals.
                </td>
              </tr>
              <tr>
                <td>Vital Interests</td>
                <td>
                  An interest which is essential for the life of the Data
                  Subject or that of another natural person..
                </td>
              </tr>
              <tr>
                <td>WHO</td>
                <td>The World Health Organization</td>
              </tr>
            </table>
          </div>
        </div>
        <div id="item2-2" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.3.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                This Policy applies to all Personal Data of living individuals
                held by WHO, contained in any form and processed in any manner.
              </li>
              <li>
                The Processing of other, non-personal data, including Anonymized
                Data, does not fall within the scope of this Policy.
              </li>
              <li>
                This Policy applies regardless of whether Processing takes place
                within one WHO office, between different WHO offices, or whether
                Personal Data are transferred to Third Parties.
              </li>
              <li>
                The respective roles and responsibilities of WHO must be defined
                prior to the Processing of Personal Data to ensure
                accountability under this Policy. In some cases, WHO will be a
                Data Controller. In other cases, the Data Controller will be a
                Third Party and WHO will be a Data Processor.
                <ol type="a">
                  <li>
                    As a Data Controller, WHO may only engage with Data
                    Processors that provide appropriate commitment and assurance
                    of meeting the requirements of this Policy or equivalent
                    Personal Data protection standards. (See paragraphs 7.1 to
                    7.3, below).
                  </li>
                  <li>
                    As a Data Processor, WHO will notify Data Controllers of its
                    Data Protection requirements, which, concerning WHO
                    workforce Personal Data, shall always remain subject to the
                    terms and conditions of employment of a Data Subject with
                    WHO. WHO will not knowingly process Personal Data received
                    that were not collected in compliance with this Policy. WHO
                    will process Personal Data on documented instructions from
                    the Data Controller or, concerning WHO workforce Personal
                    Data, as authorized by the Director-General or his delegate.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-3" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.4.heading") }}
          </h2>
          <div class="item-body">
            A. Fair and Legitimate Processing of Personal Data<br /><br />
            <ul>
              <li>
                2.1 Processing of Personal Data may only be carried out on a
                legitimate basis and in a fair and transparent manner. WHO will
                only process Personal Data based on one or more of the following
                legitimate bases<br /><br />
                <ol type="i">
                  <li>
                    <b>Consent:</b> the Data Subject has given Clear Consent to
                    the Processing of the Personal Data or, in the case of
                    Sensitive Personal Data as defined under section 3 below,
                    the Data Subject has given Explicit Consent to the
                    Processing of the Personal Data.<br /><br />
                    Wherever possible and practical, Consent should be the
                    preferred basis for Processing Personal Data. However, given
                    the exceptional circumstances in which WHO regularly
                    operates (e.g. disease outbreaks and emergencies with health
                    consequences, natural and man-made disasters, public
                    scrutiny, etc.), WHO may not always be in a position to rely
                    on this preferred basis for Processing Personal Data.
                  </li>
                  <li>
                    <b>Performance of a Contract:</b> the Processing of Personal
                    Data is necessary: (a) for a contract to which WHO and the
                    Data Subject are parties; (b) for WHO to address
                    noncompliance with the terms and conditions of employment or
                    engagement of the Data Subject with WHO; or, (c) in order to
                    take steps at the request of the Data Subject prior to
                    entering into a contract;
                  </li>
                  <li>
                    <b>Legal Obligation:</b> the Processing of Personal Data is
                    necessary for compliance with a legal obligation to which
                    WHO is subject;
                  </li>
                  <li>
                    <b>Vital Interests:</b> the Processing of Personal Data is
                    necessary in order to protect the Vital Interests of the
                    Data Subject or of another person;
                  </li>
                  <li>
                    <b>Public Interest/Task:</b> the Processing of Personal Data
                    is necessary for the performance of a task carried out in
                    the public interest or in the exercise of WHO’s public
                    health mandate;
                  </li>
                  <li>
                    <b>Legitimate Interest:</b> the Processing of Personal Data
                    is necessary for the purposes of the legitimate interests5
                    pursued by WHO or a Third Party, provided such interests are
                    not overridden by the interests or fundamental rights and
                    freedoms of the Data Subject.
                  </li>
                </ol>
              </li>
            </ul>
            B. Transparency and Information<br /><br />
            <ul>
              <li>
                2.2 Where Personal Data relating to a Data Subject are collected
                from the Data Subject, he/she will receive the following
                information at the time when the Personal Data are collected or
                as soon as possible thereafter:<br /><br />
                <ol type="i">
                  <li>the Personal Data concerned;</li>
                  <li>
                    the purpose and period for which the Personal Data will be
                    processed and stored;
                  </li>
                  <li>
                    any category of recipients of the Personal Data (to the
                    extent known at the time of collection);
                  </li>
                  <li>
                    where applicable, information that the Personal Data are
                    subject to Automated Processing;
                  </li>
                  <li>
                    the Data Subject’s right to request a copy of his/her
                    Personal Data for the purposes of verifying its accuracy and
                    completeness and to exercise the rights set out in Article
                    5, below; and
                  </li>
                  <li>
                    who to contact at WHO regarding requests concerning a Data
                    Subject’s Personal Data (Data Controller, DPPO)
                  </li>
                </ol>
              </li>
              <li>
                2.3 Where Personal Data are collected through web-based
                applications, the information referred to in paragraph 2.2 may
                be provided to the Data Subjects through the terms of use or the
                privacy policy relating to the concerned website.
              </li>
              <li>
                2.4 Where Personal Data are not collected through web-based
                applications, the Data Controller may determine, following
                consultations with the Data Protection and Privacy Officer
                (DPPO), that the provision of the information referred to in
                paragraph 2.2 is not compulsory if such provision would prove
                unnecessary under the circumstances (for example, as it may
                reasonably be considered that the Data Subject is aware of the
                information), or would involve a disproportionate effort.
              </li>
            </ul>
            C. Purpose specification and limitation<br /><br />
            <ul>
              <li>
                2.5 The Processing of Personal Data shall be relevant and
                limited to what is necessary to fulfill the specified purpose
                for which the Personal Data are collected, which will be
                determined prior to the commencement of any Processing.<br /><br />
                WHO may further process Personal Data for purposes other than
                those specified at the time of collection: i) if such Processing
                is compatible with the original purpose; ii) if the new purpose
                is covered by a legitimate basis according to paragraph 2.1
                above; iii) for statistical, historical or scientific purposes;
                iv) in the case of WHO workforce Personal Data for the purpose
                of protecting or preserving the reputation of WHO, WHO’s best
                interests, for accountability purposes, or when Personal Data is
                already in the public domain; or (v) for the purpose of the
                performance by WHO of investigations or the exercise or defence
                of legal or administrative claims, or vi) for the purpose of
                WHO’s compliance with international human rights standards,
                governmental, nongovernmental and/or United Nations inter-agency
                agreements/protocols, and/or donor funding requirements
                pertaining to allegations of serious misconduct, including, but
                not limited to, any form of sexual misconduct, physical assault,
                fraud or abuse of authority.
              </li>
            </ul>
            D. Proportionality (Data minimization)<br /><br />
            <ul>
              <li>
                2.6 The Processing of Personal Data will be:
                <ol type="i">
                  <li>
                    adequate (sufficient to properly fulfil the specified
                    purpose);
                  </li>
                  <li>
                    relevant (it will have a rational link to that purpose); and
                  </li>
                  <li>
                    limited to what is required to fulfil the purpose (and not
                    excessive for the purpose for which the Personal Data are
                    collected).
                  </li>
                </ol>
              </li>
            </ul>
            E. Accuracy<br /><br />
            <ul>
              <li>
                2.7 Personal Data should be accurate and, where necessary, kept
                up to date to fulfil the specified purpose for which the
                Personal Data were collected. All reasonable steps should be
                taken to ensure that the Personal Data are updated, where
                necessary. When inaccurate Personal Data is identified, it
                should be corrected or deleted without undue delay.
              </li>
            </ul>
            F. Confidentiality<br /><br />
            <ul>
              <li>
                2.8 Personal Data will be processed in a manner that ensures
                appropriate confidentiality of the Personal Data. Personal Data
                will be classified in accordance with an assessment of its
                sensitivity, in accordance with section XXII.3 of the eManual.
              </li>
            </ul>
            G. Time limitation for Storage<br /><br />
            <ul>
              <li>
                2.9 WHO will not keep Personal Data for a longer period than
                necessary for the purposes for which the Personal Data are
                Processed.
              </li>
            </ul>
            H. Security<br /><br />
            <ul>
              <li>
                2.10 With regard to the nature and sensitivity of Personal Data,
                appropriate organizational, physical and technical security
                measures will be implemented for both electronic and paper data
                to protect the security and integrity of Personal Data,
                including against Personal Data Breach, and to ensure its
                continued availability for the purposes for which the Personal
                Data are Processed.
              </li>
              <li>
                2.11 WHO is under an obligation to ensure an adequate level of
                data security. WHO is required to protect the integrity,
                confidentiality, and availability of Personal Data by means of
                adequate technical and organizational security measures. Data
                security measures will be routinely reviewed and updated, as
                necessary, to ensure an adequate level of data protection
                relative to the degree of sensitivity and risk exposure of the
                Personal Data.
              </li>
            </ul>
            I. Data Breaches<br /><br />
            <ul>
              <li>
                2.12 Although WHO strives to have the best available data
                security in relation to the risks of a data breach, no security
                measure (whether technical, physical or organizational) is 100%
                guaranteed to prevent a breach. It is, therefore, not only
                important to provide adequate security, but to also have a
                reliable method for detecting any security breaches and acting
                on them quickly.
              </li>
              <li>
                2.13 In the event of a Personal Data Breach, the Department of
                Information Management and Technology (IMT) or the relevant
                office, department, unit or individual who detected the breach
                shall report it to the DPPO without undue delay. The following
                information should be provided to the DPPO:<br /><br />
                <ul>
                  <li>how the breach was discovered and when;</li>
                  <li>
                    the nature of the breach, the categories of Personal Data
                    affected, and the estimated number of Data Subjects
                    concerned;
                  </li>
                  <li>
                    the possible consequences of the Personal Data Breach; and
                  </li>
                  <li>
                    any measures taken or proposed to be taken to address the
                    breach.
                  </li>
                </ul>
                <br />
                IMT should also be informed and kept closely involved at all
                stages and in all measures taken in relation to any Personal
                Data Breach. The Department of Communications (DCO) should also
                be informed of any Personal Data Breach as early as possible.<br /><br />
                WHO will promptly take all appropriate measures that reasonably
                within its power to limit the effects of a Personal Data Breach.
              </li>
              <li>
                2.14 If, based on consultations with the DPPO and other relevant
                office, department or unit, as necessary, it is determined that
                the Personal Data Breach is likely to result in a high risk to
                the rights and freedoms of natural persons, and if technically
                and legally feasible, and provided it does not require
                disproportionate efforts, WHO will communicate the Personal Data
                Breach to the Data Subjects, individually or by a public
                statement
              </li>
            </ul>
            J. Accountability<br /><br />
            <ul>
              <li>
                2.15 WHO will take appropriate measures in order to be able to
                demonstrate its compliance with the principles set out in this
                section 2.
              </li>
            </ul>
          </div>
        </div>
        <div id="item2-4" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.5.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                Personal Data revealing racial or ethnic origin, political
                opinions, religious or philosophical beliefs, or trade union
                membership, as well as genetic or biometric data uniquely
                identifying a natural person, Health Data or Personal Data
                relating to a natural person’s sex life or sexual orientation is
                considered as Sensitive Personal Data, the Processing of which
                requires particular measures.
              </li>
              <li>
                Sensitive Personal Data require an additional level of
                protection, but given the different types of data falling within
                its scope, as well as the exceptional circumstances in which WHO
                regularly operates (e.g. disease outbreaks and emergencies with
                health consequences, natural and manmade disasters, public
                scrutiny, etc.), adequate measures should be contemplated and
                determined on a case-by-case basis.
              </li>
              <li>
                The following principles will, however, always be considered in
                determining adequate measures:
                <ol type="i">
                  <li>
                    Sensitive Personal Data will be processed only when
                    absolutely necessary, subject to Article 4 below;
                  </li>
                  <li>
                    Specific technical, organizational security measures,
                    including confidentiality measures, and appropriate
                    safeguards must be considered when Processing Sensitive
                    Personal Data;
                  </li>
                  <li>
                    Sensitive Personal Data should be kept separate from other
                    types of Personal Data, wherever possible and practical, and
                    access to such Data should be controlled and limited; and
                  </li>
                  <li>
                    Conditions for Processing Sensitive Personal Data must be
                    implemented before Processing, and the DPPO must be notified
                    in advance of such Processing.
                  </li>
                </ol>
              </li>
              <li>
                In addition, a specific legitimate basis will always be given
                for Processing Sensitive Personal Data:
                <ol type="i">
                  <li>
                    <b>Explicit Consent:</b>Explicit Consent from the Data
                    Subjects should, wherever practically possible, be the
                    preferred basis for Processing Sensitive Personal Data; or
                  </li>
                  <li>
                    <b>Vital Interests:</b> the Processing of Personal Data is
                    necessary in order to protect the Vital Interests of the
                    Data Subject or of another person; or
                  </li>
                  <li>
                    <b>Public interest/task:</b> Processing is necessary for
                    reasons of overriding public interest, in particular based
                    on WHO’s public health mandate; or
                  </li>
                  <li>
                    <b>Legal proceedings:</b>Processing is necessary for the
                    performance by WHO of investigations, or for the
                    establishment, exercise or defence of legal claims; or
                  </li>
                  <li>
                    <b>Archive, Scientific and historical purposes:</b
                    >Processing is necessary for archiving purposes in the
                    public interest, scientific or historical research purposes
                    or statistical purposes.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-5" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.6.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                Where the direct purpose of the Processing of Personal Data is
                scientific research (Processing of “Research Data”), the general
                principles and requirements of this Policy will apply unless
                they are likely to render impossible or seriously impair the
                achievement of that research purpose. The Processing of Research
                Data will in any case be carried out on an adequate legal basis,
                such as the public health mandate of the WHO or its institutions
                or, as applicable, a legitimate basis enshrined in paragraph 2.1
                of this Policy.
              </li>
              <li>
                Any restriction on the applicability of the requirements under
                this Policy, based on the conditions mentioned in paragraph 4.1
                above, will:
                <ol type="i">
                  <li>
                    be limited to the minimum necessary to achieve the research
                    purpose;
                  </li>
                  <li>
                    duly consider the fundamental rights of Data Subjects;
                  </li>
                  <li>be properly documented;</li>
                  <li>
                    be supported by appropriate safeguards and controls to
                    ensure the security and confidentiality of the Personal Data
                    at all times, such as, for example, technical and
                    organizational measures for data minimization, encryption,
                    pseudonymization or anonymization.
                  </li>
                </ol>
              </li>
              <li>
                In particular, and subject to internationally-recognized ethical
                standards for scientific research being strictly adhered to,
                Research Data may be:
                <ol type="i">
                  <li>
                    processed for a different purpose than the purpose for which
                    they were originally collected provided such secondary
                    research purpose of Processing is compatible with the
                    primary purpose of Processing; and
                  </li>
                  <li>
                    stored for a longer period than the period necessary for the
                    fulfilment of the purpose for which they were collected, to
                    the extent there is a legitimate purpose for retaining the
                    data and provided such retention is regularly reviewed.
                  </li>
                </ol>
                As a general principle, scientific research will not be deemed
                to be incompatible with the primary purpose of Processing
                Research Data, unless there are clear indications to the
                contrary or it can be clearly demonstrated otherwise
              </li>
              <li>
                Where the Research Data exemption mentioned in paragraph 4.1
                above is relied on, it should be evaluated, in light of the
                risks to the rights and freedoms of the Data Subjects, whether a
                “data protection impact assessment” will be carried out. A data
                protection impact assessment is likely to be required if the
                Processing is not based on the informed consent of the Data
                Subjects as the legal basis and if such Processing is likely to
                imply substantive risks for the rights and freedoms of the Data
                Subjects. The safeguards implemented for the protection of the
                Research Data and the privacy of Data Subjects must be
                specifically reviewed, and the rights of Data Subjects must be
                balanced against the principle of freedom of research.
                Appropriate records of the Research Data that have been
                Processed, and information regarding the safeguards established,
                will be kept on file.
              </li>
              <li>
                Where Personal Data are Processed for the purpose of scientific
                research, the rights of Data Subjects will be honoured, and may
                be limited only insofar as the exercise thereof is likely to
                make the achievement of the objectives of the research
                impossible, or impedes it to a significant extent.
              </li>
              <li>
                Regarding the transparency of Processing activities, adequate
                information will be provided to the Data Subjects, unless
                providing such information would be impossible or would involve
                11 / 15 disproportionate efforts. In cases where Data Subjects
                are not informed directly, appropriate alternative measures must
                be taken, such as, for example, making the information regarding
                the Processing activities publicly available.
              </li>
              <li>
                In case the exact purpose of the Processing of Research Data is
                not known at the time of data collection or transfer, the
                research purpose will be defined and documented adequately and
                in a timely manner, and any subsequent change to the purpose of
                Processing will be documented, as applicable.
              </li>
              <li>
                For the avoidance of doubt, all other obligations related to
                Research Data, including, but not limited to, applicable
                regulatory requirements, and internationally-recognized
                professional and ethical standards and the WHO Research Ethics
                Review Committee’s Guidelines, will be complied with.
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-6" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.7.heading") }}
          </h2>
          <div class="item-body">
            Data Subjects have the rights set out in this Article 5. The
            exercise of these rights and the processing of requests by Data
            Subjects will be done in accordance with paragraphs 6.4 and 6.5
            below.<br /><br />
            <ol type="A">
              <li>
                <i>Information</i><br />
                The Data Subject has the right to receive the information
                mentioned in para. 2.2 where Personal Data relating to him/her
                are collected by WHO
              </li>
              <li>
                <i>Access</i><br />
                The Data Subject has the right to request a copy of his/her
                Personal Data for the purposes of verifying its accuracy and
                completeness.
              </li>
              <li>
                <i>Rectification</i><br />
                The Data Subject has the right to request rectification of
                his/her Personal Data if it is inaccurate or incomplete. WHO may
                request the Data Subject to demonstrate the alleged inaccuracy
                or incompleteness. WHO will consider such requests and take
                appropriate action if it finds that the Personal Data is
                inaccurate or incomplete, subject to paragraphs 6.4 and 6.5
                below
              </li>
              <li>
                <i>Deletion</i><br />
                The Data Subject has the right to request deletion of his/her
                Personal Data. WHO will consider such requests and, subject to
                paragraphs 6.4 and 6.5 below, will take appropriate action if:
                <ol type="i">
                  <li>
                    such Personal Data are no longer necessary for the purpose
                    of the Processing;
                  </li>
                  <li>
                    the Data Subject has withdrawn his/her consent to the
                    Processing of the Personal Data which is based exclusively
                    on such consent; or
                  </li>
                  <li>
                    the Personal Data must be deleted in order to comply with a
                    legal obligation incumbent on WHO.
                  </li>
                </ol>
              </li>
              <li>
                <i>Restriction of the Processing of Personal Data</i><br />
                The Data Subject has the right to request that WHO restricts the
                Processing of his/her Personal Data
              </li>
              <li>
                <i>Withdrawing Consent</i><br />
                The Data Subject has the right to withdraw his/her Consent at
                any time where WHO relied on his/her Consent to process his/her
                Personal Data. The withdrawal of consent will not affect any
                Processing of that Personal Data before its withdrawal
              </li>
              <li>
                <i>Objection</i><br />
                The Data Subject has the right to object to the Processing of
                his/her Personal Data. If the objection is justified, WHO will
                no longer process the Personal Data concerned for the purpose(s)
                related to the objection. WHO will inform the Data Subject of
                the consequences of his/her objection, such as, for example, WHO
                being entitled to terminate any relevant contract or other
                relationship without incurring any liability. A request will not
                be considered justified if there are compelling legitimate
                grounds for the Processing which override the interests, rights
                and freedoms of the Data Subject or for the performance by WHO
                of investigations or for the establishment, exercise or defence
                of legal claims or to protect or preserve the reputation and/or
                interests of WHO.
              </li>
              <li>
                <i>Automated Processing</i><br />
                The Data Subject has the right not to be subject to Automated
                Processing, which may adversely affect the Data Subject’s legal
                rights or similarly significantly affect him/her, unless it is
                necessary for entering into, or performance of, a contract
                between the Data Subject and WHO or is based on the Data
                Subject’s Explicit Consent.
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-7" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.8.heading") }}
          </h2>
          <div class="item-body">
            A. Role and Responsibilities
            <ol>
              <li>
                With a view to overseeing the implementation of this Policy, the
                DPPO will be designated by the WHO Director-General and will be
                a member of the WHO Data Governance Committee (DGC). The DPPO
                will consult the Chief Information Officer (CIO) and the Office
                of the Legal Counsel (LEG), as appropriate.
              </li>
              <li>
                The DPPO will be an independent function and report directly to
                the Director-General. Such reporting line will not affect, in
                any manner, his/her independence in the performance of his/her
                duties.
              </li>
              <li>
                The DPPO’s main functions and responsibilities will be exercised
                with objectivity and confidentiality, in consultation with the
                CIO and LEG, as appropriate, and are as follows:
                <ol type="a">
                  <li>
                    Acting as first and main point of contact concerning all
                    Personal Data Protection matters;
                  </li>
                  <li>
                    Providing advice, support and training on data protection
                    and on this Policy;
                  </li>
                  <li>
                    Monitoring and reporting on compliance with this Policy;
                  </li>
                  <li>
                    Providing Data Subjects with information about their rights
                    and handling requests from Data Subjects, as per the process
                    established below under section B below; 13 / 15
                  </li>
                  <li>
                    As appropriate, engaging in discussions with other
                    international organizations to share common challenges and
                    best practices across United Nations agencies on Personal
                    Data protection;
                  </li>
                  <li>
                    Ensuring that this Policy is regularly reviewed in light of
                    relevant developments and making related recommendations to
                    the Director-General;
                  </li>
                  <li>
                    Taking appropriate action in the event of a Personal Data
                    Breach in accordance with paragraphs 2.13 to 2.16 above.
                  </li>
                </ol>
              </li>
            </ol>
            B. Contacting the DPPO
            <ol>
              <li>
                As mentioned under Article 5 above, Data Subjects are granted
                certain rights with respect to their Personal Data. All requests
                from Data Subjects must:
                <ol type="a">
                  <li>
                    be submitted by the Data Subject by contacting the DPPO at
                    the following email: dataprotection@who.int or at the
                    following address: World Health Organization, Data
                    Protection and Privacy Officer, 20 Avenue Appia, 1211 Geneva
                    11, Switzerland.
                  </li>
                  <li>
                    include sufficient information and documentation to prove
                    that the person making the request is the individual to whom
                    the Personal Data relates.
                  </li>
                  <li>
                    indicate the specific area and/or activities in relation to
                    which WHO is Processing the Data Subject’s Personal Data
                    (e.g. recruitment procedures, registration for WHO meetings,
                    procurement, etc.), along with an indication of the date on
                    which the Personal Data were collected and other relevant
                    information.
                  </li>
                </ol>
              </li>
              <li>
                WHO may, following consultations with the DPPO, reject a
                request, in whole or in part, where:
                <ol type="a">
                  <li>
                    there are grounds for believing that the request is
                    manifestly abusive, fraudulent or obstructive to the purpose
                    of Processing;
                  </li>
                  <li>
                    complying with the request proves to be impossible,
                    inappropriate, or would involve a disproportionate effort;
                  </li>
                  <li>
                    the Processing of the Personal Data is necessary for
                    archiving or statistical purposes, or to protect the
                    interests of the Data Subject;
                  </li>
                  <li>
                    the Processing of the Personal Data is necessary for the
                    performance of a task carried out in the exercise of
                    official authority vested in the Organization;
                  </li>
                  <li>
                    the Processing of the WHO workforce Personal Data is
                    necessary to protect and/or preserve the reputation of WHO,
                    WHO’s best interests, for accountability purposes, or when
                    the WHO workforce Personal Data is already in the public
                    domain;
                  </li>
                  <li>
                    the Processing of the Personal Data is necessary for the
                    compliance with a legal obligation, the performance by WHO
                    of investigations or the exercise or defence of legal
                    claims;
                  </li>
                  <li>
                    the Processing of the Personal Data is necessary for the
                    compliance with international human rights standards,
                    governmental, non-governmental and/or United Nations
                    inter-agency 14 / 15 agreements/protocols, and/or donor
                    funding requirements pertaining to the investigation of
                    allegations of serious misconduct, including, but not
                    limited to, any form of sexual misconduct, abusive conduct,
                    fraud or abuse of authority.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-8" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.9.heading") }}
          </h2>
          <div class="item-body">
            A. Transfer to Contractors, UN system Agencies, Implementing
            Partners, etc.<br />
            <ol>
              <li>
                Personal Data may be transferred or disclosed to Third Parties,
                including but not limited to contractors, vendors of goods and
                services (including cloud service providers), other UN system
                agencies, implementing partners and collaborating institutions,
                where the Third Party provides adequate safeguards to protect
                the Personal Data and the privacy rights of Data Subjects and
                following the conclusion of a written agreement or in accordance
                with a UN Protocol. Any transfer of Personal Data must be
                consistent with this Policy
              </li>
              <li>
                A Personal Data transfer agreement mentioned at paragraph 7.1,
                above, shall, at a minimum, include an undertaking on the part
                of the Third Party that:
                <ol type="a">
                  <li>
                    an adequate level of protection (i.e. sufficient taking into
                    account the type of Personal Data concerned, and the purpose
                    of the disclosure) will be provided as regards to the
                    Personal Data transferred by WHO;
                  </li>
                  <li>
                    the supplied Personal Data will be used only in accordance
                    with the terms of the agreement, and not for any purpose not
                    contemplated in the agreement;
                  </li>
                  <li>
                    all Personal Data will be returned to WHO and/or destroyed
                    at any time upon request by WHO and, unless otherwise
                    agreed, at the expiration or termination of the agreement.
                    The Third Party shall confirm to WHO in writing that it has
                    complied with the foregoing no later than seven (7) days
                    after receipt of the notification from WHO;
                  </li>
                  <li>
                    sufficient security safeguards (such as encryption, storage
                    in secure location, setting up of antivirus software and
                    firewalls, etc.) will be implemented to prevent Personal
                    Data Breach;
                  </li>
                  <li>
                    none of the work will be subcontracted without WHO’s prior
                    consent; and
                  </li>
                  <li>
                    WHO will be promptly notified in the event that a Personal
                    Data Breach occurs and that the Third Party will cooperate
                    with WHO in taking appropriate action in response to the
                    Personal Data Breach, including appropriate measures to
                    limit the effects of a Personal Data Breach.
                  </li>
                </ol>
              </li>
              <li>
                Notwithstanding paragraphs 7.1 and 7.2, above, where WHO is the
                Data Processor, it may only engage with sub-processors with the
                consent of the Data Controller, and where the subprocessors
                agree to comply with the same obligations with regard to
                Personal Data Protection as agreed between WHO and the Data
                Controller.
              </li>
              <li>
                It is recognized that it may not always be possible to conclude
                a written agreement prior to sharing Personal Data with certain
                partners and in certain exigent circumstances, such as disease
                outbreaks and emergencies with health consequences. In such
                circumstances, and where transfers are necessary to protect the
                Vital Interests of affected persons, all steps should be taken
                as soon as possible after the relevant outbreak or emergency, to
                protect the transferred Personal Data, including through
                pursuing a written agreement.
              </li>
            </ol>
            B. Disclosure to Authorities
            <ol>
              <li>
                Nothing in or relating to this Policy shall be deemed as
                regulating or limiting the communication by WHO of information –
                including Personal Data – to appropriate authorities to
                facilitate the proper administration of justice.
              </li>
              <li>
                As such, this Policy shall not prejudice any cooperation that
                may take place between WHO and government entities and law
                enforcement agencies (such as police authorities or national
                courts).
              </li>
            </ol>
            C. No Commercial Disclosure
            <ol>
              <li>WHO will not sell Personal Data to Third Parties.</li>
            </ol>
          </div>
        </div>
        <div id="item2-9" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.10.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                Nothing contained in or relating to this Policy, or done
                pursuant to it, shall be construed as a waiver of any of the
                privileges and immunities enjoyed by WHO under national or
                international law, and/or as submitting WHO to any national
                court jurisdiction. Without limiting the generality of the
                previous sentence, any disclosure of Personal Data in response
                to a request for disclosure in accordance with this Policy, will
                not constitute a waiver, express or implied, of any of the
                privileges and immunities of WHO.
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-10" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.11.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                This Policy enters into force on the date it is signed by the
                WHO Director-General. It will be implemented progressively over
                a period of two years following its entry into force. Personal
                Data hosted by WHO should be fully compliant with the Policy.
                All new Information Technology systems introduced by WHO
                following its entry into force should be fully compliant with
                the Policy. Information Technology systems introduced by WHO
                before its entry into force might not be compliant with the
                Policy due to technical limitations.
              </li>
              <li>
                This Policy will be evaluated by the WHO Secretariat after the
                initial two-year transition period and will be reviewed by the
                Secretariat thereafter from time to time. WHO may amend this
                Policy at any time, without prior notification, by posting the
                new version on its website at www.who.int.
              </li>
              <li>
                Any amendments to this Policy shall become effective on the date
                the revised Policy is posted on WHO’s website. Personal Data
                will be handled in accordance with the Policy that was in effect
                at the time of collection.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
<script>
// Layouts
import LayoutManager from "@/components/Layout/LayoutManager";
import LandingPageLayout from "@/components/Layout/LandingPageLayout.vue";
import { LxpBreadcrumb } from "didactica";
import { mapGetters } from "vuex";

export default {
  name: "TermsConditionsPage",
  components: {
    LayoutManager,
    LandingPageLayout,
    LxpBreadcrumb
  },
  computed: {
    ...mapGetters(["isSystemAlertEnabled"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    breadcrumb1() {
      return [this.homeBreadcrumbPath, this.category1Path];
    },
    breadcrumb2() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.1.heading"),
          active: true
        }
      ];
    },
    breadcrumb3() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.2.heading"),
          active: true
        }
      ];
    },
    breadcrumb4() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.3.heading"),
          active: true
        }
      ];
    },
    breadcrumb5() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.4.heading"),
          active: true
        }
      ];
    },
    breadcrumb6() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.5.heading"),
          active: true
        }
      ];
    },
    breadcrumb7() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.6.heading"),
          active: true
        }
      ];
    },
    breadcrumb8() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.7.heading"),
          active: true
        }
      ];
    },
    breadcrumb9() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.8.heading"),
          active: true
        }
      ];
    },
    breadcrumb10() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.9.heading"),
          active: true
        }
      ];
    },
    breadcrumb11() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.10.heading"),
          active: true
        }
      ];
    },
    breadcrumb12() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.11.heading"),
          active: true
        }
      ];
    }
  },
  data() {
    return {
      disclaimerIcon: require("@/assets/images/landing/information.svg"),
      isDesktop: false,
      isTab: false,
      isMobile: false,
      openSubMenus: {},
      currentItem: this.$t("terms_conditions.category1"),
      navItems: [
        {
          id: "item1",
          name: this.$t("terms_conditions.category1"),
          subItems: [{ id: "item1", name: "Disclaimer" }]
        },
        {
          id: "item2",
          name: this.$t("terms_conditions.category2"),
          subItems: [
            {
              id: "item2",
              name: this.$t("terms_conditions.table_contents.1.heading")
            },
            {
              id: "item2-1",
              name: this.$t("terms_conditions.table_contents.2.heading")
            },
            {
              id: "item2-2",
              name: this.$t("terms_conditions.table_contents.3.heading")
            },
            {
              id: "item2-3",
              name: this.$t("terms_conditions.table_contents.4.heading")
            },
            {
              id: "item2-4",
              name: this.$t("terms_conditions.table_contents.5.heading")
            },
            {
              id: "item2-5",
              name: this.$t("terms_conditions.table_contents.6.heading")
            },
            {
              id: "item2-6",
              name: this.$t("terms_conditions.table_contents.7.heading")
            },
            {
              id: "item2-7",
              name: this.$t("terms_conditions.table_contents.8.heading")
            },
            {
              id: "item2-8",
              name: this.$t("terms_conditions.table_contents.9.heading")
            },
            {
              id: "item2-9",
              name: this.$t("terms_conditions.table_contents.10.heading")
            },
            {
              id: "item2-10",
              name: this.$t("terms_conditions.table_contents.11.heading")
            }
          ]
        }
      ],
      homeBreadcrumbPath: {
        icon: null,
        text: this.$t("general.menu.home"),
        to: { name: "Home" }
      },
      category1Path: {
        icon: null,
        text: this.$t("terms_conditions.category1"),
        active: true
      },
      category2Path: {
        icon: null,
        text: this.$t("terms_conditions.category2"),
        active: true
      }
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.onResize();
    });
    this.onResize();

    if (this.isDesktop || this.isTab) {
      this.navItems.forEach(item => {
        this.$set(this.openSubMenus, item.id, true);
      });
    }

    if (this.isMobile) {
      const observer = new MutationObserver(() => {
        let activeLink;
        if (this.isDesktop || this.isTab) {
          activeLink = document.querySelector("#terms-list .nav-link.active");
        } else {
          activeLink = document.querySelector(
            "#mobile-nav .dropdown-item.active"
          );
        }
        if (activeLink) {
          this.currentItem = activeLink.textContent.trim();
        }
      });
      observer.observe(document.body, {
        attributes: true,
        subtree: true,
        attributeFilter: ["class"]
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.onResize();
    });
  },
  methods: {
    setCurrentItem(item) {
      this.currentItem = item;
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    scrollToContent(id, name) {
      this.setCurrentItem(name);
      const headerHeight = this.isAuthenticated
        ? document.getElementById("user-fluid-layout__top")?.offsetHeight
        : document.getElementById("lp-header")?.offsetHeight;
      const mobileNavHeight = document.getElementById("mobile-nav")
        ?.offsetHeight;
      const totalTopHeight = headerHeight + mobileNavHeight;
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition - totalTopHeight + window.scrollY;
        window.scrollTo({
          top: offsetPosition
        });
      }
    },
    toggleMenu(id) {
      this.$set(this.openSubMenus, id, !this.openSubMenus[id]);
    },
    isSubMenuOpen(id) {
      return !!this.openSubMenus[id];
    },
    onResize() {
      this.isDesktop = window.innerWidth > 991;
      this.isTab = window.innerWidth > 767 && window.innerWidth < 992;
      this.isMobile = window.innerWidth < 768;
      this.mobileNavTop();
      if (this.isAuthenticated) {
        this.updatePanelStyle();
      }
    },
    mobileNavTop() {
      let topMargin = document.getElementById("lp-header")?.offsetHeight;
      document.getElementById("mobile-nav").style.top = topMargin + "px";
    },
    updatePanelStyle() {
      this.$nextTick(() => {
        const headerHeight = document.getElementById("user-fluid-layout__top")
          ?.offsetHeight;
        const breadcrumbHeight = document.getElementById("top-breadcrumb")
          ?.offsetHeight;
        const totalOffset = headerHeight + 2;
        const totalHeight = totalOffset + breadcrumbHeight;
        const leftContentElement = document.getElementById("terms-list");
        const rightContentElements = document.querySelectorAll(
          ".terms__conditions--container .content__item--container > .content-item"
        );
        const termsAndConditionsPage = document.getElementById("terms-page");

        if (leftContentElement) {
          leftContentElement.style.top = `${totalOffset}px`;
          leftContentElement.style.height = `calc(100vh - ${totalHeight}px)`;
        }
        if (rightContentElements.length > 0) {
          rightContentElements.forEach(element => {
            element.style.minHeight = `calc(100vh - ${headerHeight}px)`;
          });
        }
        if (termsAndConditionsPage) {
          termsAndConditionsPage.style.marginTop = `${headerHeight}px`;
        }
      });
    }
  },
  watch: {
    "$route.name"() {
      this.updatePanelStyle();
    },
    isSystemAlertEnabled() {
      setTimeout(() => {
        this.mobileNavTop();
        if (this.isAuthenticated) {
          this.updatePanelStyle();
        }
      }, 100);
    }
  }
};
</script>
<style scoped lang="scss">
.terms__conditions--container {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-family: $font-family;
  &.container {
    max-width: 1280px;
  }
  > .lxp-breadcrumb {
    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 0;
  }
  .heading__item--container {
    border-right: 1px solid $brand-neutral-200;
    background-color: $brand-primary-75;
    padding: 24px;
    .nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      > div {
        > .nav-link {
          display: flex;
          justify-content: space-between;
        }
      }
      > .nav-link {
        margin-top: 4px;
      }
    }
    .nav-link {
      padding: 8px;
      border-radius: 4px;
      color: $brand-primary;
      word-break: break-word;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.1px;
      width: 100%;
      &:hover {
        background-color: $brand-primary-100;
      }
      &.active:not(.no-active) {
        color: $brand-neutral-0;
        background-color: $brand-primary;
      }
      + .nav {
        .nav-link {
          font-weight: 400;
          color: $brand-neutral-700;
          padding: 8px 24px;
          &.active {
            color: $brand-neutral-0;
            background-color: $brand-primary;
          }
        }
      }
      &.dropdown-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $brand-neutral-900;
        &:after {
          display: none;
        }
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .navbar-nav {
      width: 100%;
      .nav-item.dropdown {
        position: relative;
        &.show {
          .dropdown-toggle {
            color: $brand-primary;
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
      .dropdown-menu {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 8px;
        background-color: $brand-primary-75;
        padding: 8px;
        height: 300px;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          border-radius: 8px;
          border: none;
          background-color: $brand-neutral-75;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: none;
          background-color: $brand-neutral-300;
        }
        .dropdown-item {
          border-radius: 4px;
          padding: 8px;
          color: $brand-primary;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.1px;
          word-break: break-word;
          text-wrap: wrap;
          &.active {
            color: $brand-neutral-0;
            background-color: $brand-primary;
          }
        }
        .subdropdown-menu {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            margin-top: 4px;
          }
          .dropdown-item {
            padding: 8px 8px 8px 24px;
            font-weight: 400;
            color: $brand-neutral-700;
            &.active {
              color: $brand-neutral-0;
              background-color: $brand-primary;
            }
          }
        }
      }
    }
  }
  .content__item--container {
    position: relative;
    padding: 0px 56px 56px;
    .disclaimer-information {
      display: flex;
      align-items: flex-start;
      padding: 8px 16px;
      border-radius: 16px;
      background-color: $brand-primary-100;
      margin-bottom: 16px;
      @include body-medium;
      color: $brand-neutral-700;
      img {
        margin-right: 16px;
      }
    }
    .content-item {
      padding-top: 16px;
    }
    .main-heading {
      color: $brand-primary;
      font-size: 36px;
      font-weight: 400;
      line-height: 43.2px;
      margin-bottom: 16px;
    }
    .item-heading {
      color: $brand-neutral-1000;
      font-size: 24px;
      font-weight: 400;
      line-height: 28.8px;
      margin-bottom: 16px;
    }
    .item-body {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.25px;
      word-break: break-word;
      text-wrap: wrap;
      color: $brand-neutral-1000;
      ul,
      ol {
        li {
          margin-top: 8px;
        }
      }
      ul {
        list-style-type: none;
      }
    }
    table {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
        td {
          color: $brand-neutral-900;
          border: 1px solid $brand-neutral-200;
          &:first-child {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.15px;
            padding: 8px;
            border-left: none;
            width: 200px;
          }
          &:last-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.25px;
            padding: 8px 24px;
            border-right: none;
          }
        }
      }
    }
  }
  .mobile__nav--wrapper {
    width: 100%;
    height: 56px;
    background-color: $brand-primary-50;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
  &.loggedin-terms {
    width: calc(100% - $ufl-sidenav-expand-width);
    margin-left: $ufl-sidenav-expand-width;
    padding-top: 2px;
    padding-left: 48px;
    padding-right: 48px;
    position: relative;
    background-color: $brand-primary-50;
    .heading__item--container {
      width: 260px;
      padding: 24px 16px;
      border-top-left-radius: 8px;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
    .content__item--container {
      width: calc(100% - 260px);
      padding: 0px 32px;
      border-top-right-radius: 8px;
      background-color: $brand-neutral-0;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
  }
}
.user-fluid-layout__side-nav-wrapper {
  &:not(.expand) {
    + .loggedin-terms {
      width: calc(100% - ($ufl-sidenav-default-width + 20px));
      margin-left: $ufl-sidenav-default-width + 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .terms__conditions--container {
    justify-content: flex-end;

    .heading__item--container {
      position: sticky;
      top: 64px;
      left: 0;
      width: 380px;
      height: calc(100vh - 64px);
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        border: none;
        background-color: $brand-primary-75;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: none;
        background-color: $brand-neutral-200;
      }
    }

    .content__item--container {
      width: calc(100% - 380px);

      .content-item {
        min-height: calc(100vh - 104px);
      }
    }
  }

  .system-alert {
    .terms__conditions--container {
      .heading__item--container {
        top: 104px;
        height: calc(100vh - 104px);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .terms__conditions--container {
    .heading__item--container {
      width: 260px;
      padding: 24px 16px;
    }

    .content__item--container {
      width: calc(100% - 260px);
      padding: 0px 32px 32px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .terms__conditions--container {
    flex-direction: column;
    padding-top: 0;
    overflow: hidden;

    .heading__item--container {
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 4px;

      .nav-item.dropdown {
        margin: 0 !important;
      }

      .nav-link {
        &:hover {
          background-color: transparent;
        }
        &.dropdown-toggle {
          background-color: $brand-primary-100;
          margin: 0 !important;
        }
      }
    }

    .content__item--container {
      width: 100%;
      padding: 56px 0px 16px;

      .disclaimer-information {
        img {
          margin-right: 8px;
        }
      }

      table tr td {
        &:first-child {
          width: 35%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.terms__conditions--container {
  .lxp-breadcrumb {
    font-family: $font-family;
    margin-bottom: 16px;
    .breadcrumb {
      padding: 0;
      margin: 24px 0px 0px;

      .breadcrumb-item {
        a {
          @include body-medium;
          color: $brand-neutral-700;
        }

        &.active {
          a {
            color: $brand-primary;
            font-weight: 500;
          }
        }

        + .breadcrumb-item {
          &:before {
            position: relative;
            top: 2px;
            color: $brand-neutral-700;
          }
        }
      }
    }
  }
  > .lxp-breadcrumb {
    .breadcrumb {
      margin-top: 0px;
    }
  }
}
</style>
